import { get, post } from "../lib/request";
import moment from 'moment'

/**
 * @description calling get api method from to get all booking list for doctor
 * @author Jagannath
 * @date 2020-09-16
 * @param list query
 * @return {*} Object
 */
export const getDoctorBookings = (list) => {
    if(!list.toDate){
        const endpoint = `provider/bookings?from=${list.fromDate}`;
        return get(endpoint)
    }
    const endpoint = `provider/bookings?from=${list.fromDate}&to=${list.toDate}`;
    return get(endpoint)
}

/**
 * @description calling get api method from to get all booking list for patients
 * @author Jagannath
 * @date 2020-09-16
 * @param list query
 * @return {*} Object
 */
export const getPatientBookings = (list) => {
    if(!list.toDate){
        const endpoint = `customer/bookings/date?from=${list.fromDate}`;
        return get(endpoint)
    }
    const endpoint = `customer/bookings/date?from=${list.fromDate}&to=${list.toDate}`;
    return get(endpoint)
}

/**
 * @description get method for getting call details by booking id
 * @author Jagannath
 * @date 2020-09-16
 * @param list query
 * @return {*} Object
 */
export const getCallStatus = (bookingId) => {
    return get(`customer/booking?bookingId=${bookingId}`)
}

/**
 * @description calling get api to get conference token by booking id
 * @author Jagannath
 * @date 2020-09-16
 * @param list query
 * @return {*} Object
 */
export const getConferenceToken = (id) => {
    const data = {
        "bookingId": String(id)
      }
    return post('meet/call', data)
}

export const providerBookingById = (bookingId) => {
    return get(`provider/booking/id?bookingId=${bookingId}`)
}

export const customerBookingById = (bookingId) => {
    return get(`customer/booking?bookingId=${bookingId}`)
}

 /**
 * @description method calling api to get booking history 
 * of provider by date filter
 * @author Jagannath
 * @date 2020-10-13
 * @param list query {startDate: ''}
 * @return {*} 
 */
export const providerBookingHistory = (list) => {
    return get(`provider/bookingHistory?startDate=${moment(list.startDate).format('YYYY-MM-DD')}`);
}