import { get, postWithToken, patch, patchWithToken, deleteReq } from "../lib/request";

/**
 * @description get method for getting call details by booking id
 * @author Jagannath
 * @date 2020-09-16
 * @param list query
 */
export const updateProviderStatus = (list) => {
    const data = {
        "status": list.status || 3,
        // "latitude": list.latitude || "13",
        // "longitude": list.longitude || "77"
      }
    return patchWithToken(`provider/status`, data)
}

/**
 * @description POST api to add medication
 * @author Jagannath
 * @date 2020-09-16
 * @param data payload
 */
export const postMedication = (data) => {
  return postWithToken(`provider/medication`, data)
}


/**
 * @description GET api to get medications
 * @author Jagannath
 * @date 2020-09-16
 * @param list query
 */
export const getMedications = (id) => {
  return get(`provider/medication?bookingId=${id}&medicationId=0`)
}

/**
 * @description PATCH api to update a medication
 * @author Jagannath
 * @date 2020-09-16
 * @param data payload
 */
export const updateMedication = (data) => {
  return patchWithToken(`provider/medication`, data)
}

/**
 * @description DELETE api to remove medication
 * @author Jagannath
 * @date 2020-09-16
 * @param bookingId booking Id
 * @param medicationId medicationId
 */
export const deleteMedication = (bookingId, medicationId) => {
  return deleteReq(`provider/medication?bookingId=${bookingId}&medicationId=${medicationId}`)
}


 /**
 * @description PATCH api to change booking status
 * @author Jagannath
 * @date 2020-09-29
 * @param data payload
 */
export const completeAppointment = (data) => {
  return patchWithToken(`provider/bookingStatus`, data)
}


export const prescriptionMetaData = () => {
  return get('provider/prescription')
}


// export const 