// Dependency Imports
import React from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route, Switch, Redirect, useLocation } from 'react-router-dom'
import { IntlProvider } from "react-intl";
import PropTypes from 'prop-types';

import './static/scss/common.scss'
import * as LANG_SET from './translations/main';
import DoctorLogin from './pages/login/DoctorLogin';
import {reactLocalStorage} from 'reactjs-localstorage';
import DoctorBookings from './pages/doctor-bookings/DoctorBookings';
import Conference from './pages/conferenceRoom/Conference';
import ForgotPassword from './pages/login/ForgotPassowrd';
import ThankYouPage from './pages/thankYou/ThankYouPage';
import Bookings from './pages/bookings/Bookings';
import PatientBookings from './pages/patient-bookings/PatientBookings';
import Prescription from './pages/prescription/Prescription';
// import { VirgilCrypto } from 'virgil-crypto';
// import {firebase} from './firebase';
export class App extends React.Component {
  constructor(props) {
    super(props)

  }
  
  
  /**
   * @description validating routes
   * @author Jagannath
   * @date 2020-09-16
   * @param props
   */
  AuthRoute = (props) => {
    
    if(reactLocalStorage.get('token')){
      return <Route exact {...props} />
    }
    else {  
      return <Redirect to='/login'  />
      
    }
  }
  componentDidMount(){ }
  
  render(){
    const AuthRoute = this.AuthRoute;
    const { lang } = this.props;
    return (
      <IntlProvider locale={lang} key={lang} messages={LANG_SET[lang]}>
        <Router>
          <Switch>
            <Route exact path="/login" component={DoctorLogin} />
            <Route exact path="/forgot" component={ForgotPassword} />
            <AuthRoute path="/doctor-bookings" component={DoctorBookings} />
            <AuthRoute path="/patient-bookings" component={PatientBookings} />
            <AuthRoute path="/conference" component={Conference} />
            <AuthRoute path="/thankyou" component={ThankYouPage} />
            <AuthRoute path="/prescription" component={Prescription} />
            <AuthRoute path="/home" component={Bookings} />
            <AuthRoute path="/" component={Bookings} ></AuthRoute>
          </Switch>
        </Router>
      </IntlProvider>
    )
  }
}

// Create validations for Props, required or type etc.
App.propTypes  = {
  lang: PropTypes.any.isRequired
}

const mapStateToProps = state => {
  return {
    reduxState: state,
    lang: state.lang
  }
}
export default connect(mapStateToProps)(App)
