// import React from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';

export default async function VirgilSecurity(encryptedQA) {

    var virgilCrypto = await window.virgilCrypto;
    var encryptedMessage = encryptedQA;
    const bobPrivateKey = reactLocalStorage.get('privateKey')

    // formating private key to base64array
    var privateKey = virgilCrypto.importPrivateKey(bobPrivateKey);

    // decrypt the encrypted data using a private key
    const decryptedData = virgilCrypto.decrypt(encryptedMessage, privateKey);

    // converting uni8array buffer to object
    var enc = new TextDecoder("utf-8");
    const QuesAns = JSON.parse(enc.decode(decryptedData));

    // sending decrypted question answer to user dialog component
    return QuesAns
}
