// eslint-disable-next-line no-unused-vars
import * as actionTypes from './actions/actionTypes'

export const initialState = {
  testKey: false,
  lang: 'en',
  showProfile: false,
  bookingId: null,
  selectedDate: new Date(),
  newTab : null,
  bookingDetails: []
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // create your cases and update state here
    case actionTypes.LANGUAGE_CHANGE:
      // return {
      //   ...state
      // }
    
    return {
      ...state,
      lang: action.lang
    }

    case actionTypes.SHOW_PATIENT_PROFILE:
      return {
        ...state,
        showProfile: action.data
      }
    
    case actionTypes.BOOKING_ID:
      return {
        ...state,
        bookingId: action.data
      }
    case actionTypes.SET_SELECTED_DATE:
      return {
        ...state,
        selectedDate: action.data
      }
    case actionTypes.SET_NEW_TAB:
      return {
        ...state, 
        newTab: action.data
      }
    case actionTypes.SET_BOOKING_DATA:
      return {
        ...state, 
        bookingDetails: action.data
      }
    default: return state
  }
}

export default reducer
