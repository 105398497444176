import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { bookingsHandler } from './DailyBookings'
import moment from 'moment'
import './Bookings.css'
import { getPatientBookings,getDoctorBookings } from '../../services/service';
import { reactLocalStorage } from 'reactjs-localstorage';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

/**
 * @description functional component
 * for showing multiple booking for 10 days
 * @author Jagannath
 * @date 2020-09-15
 * @export SimpleAccordion component
 * @param props props 
 * stateData: current date bookings 
 * openProfileDialog: method to open profile from parent 
 * @return {*} 
 */
export default function BookingTables(props) {
  // console.log(props.stateData)
  const classes = useStyles();
  const [state, setState] = React.useState([])
  const [isOpen, setIsOpen] = React.useState(true)
  const dummyList = [1,2,3,4,5,6,7,8,9,10]

  /**
   * @description getting booknigs for next 10 days
   * @author Jagannath
   * @date 2020-09-15
   */
  const getBookings = async() =>{
    const list = {
      fromDate: new Date().setHours(0,0,0,0).valueOf()/1000,
      toDate: moment(moment().add(10, 'day').valueOf()).startOf('day').valueOf()/1000
    }
    if(reactLocalStorage.get('userType') == 2){
      getPatientBookings(list)
        .then((res)=>{
            setState(res.data.data.upcoming.filter(x=>x.callType == 3))
        })
        .catch((err)=>{
            console.log(err);
        })
    }else{
      getDoctorBookings(list)
        .then((res)=>{
            setState(res.data.data.filter(x=>x.callType == 3))
        })
        .catch((err)=>{
            console.log(err);
        })
    }
  }

  /**
   * @description initial life of component
   * @author Jagannath
   * @date 2020-09-15
   */
  React.useEffect(() => {
    getBookings()
  }, [])

  /**
   * @description method for getting showing single accordian for single date
   * @author Jagannath
   * @date 2020-09-15
   * @param day date-day
   * @return {*} accordion component
   */
  const getNextBookings = (day) => {
    return (
      <Accordion className="root" key={day}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          style={{}}
          id="panel1a-header">
            <Typography className={classes.heading}>
                {moment().add(day, 'day').format("MMM Do YY")}
            </Typography>
            <hr className="accordion-hr" />
          </AccordionSummary>
          <AccordionDetails style={{}}>
              {
                bookingsHandler({
                  data:state.filter((item,i)=>{
                    return (
                      (item.bookingRequestedFor*1000) >= 
                      (moment(moment().add(day, 'day').valueOf()).startOf('day').valueOf()) && 
                      (item.bookingRequestedFor*1000) <= 
                      (moment(moment().add(day+1, 'day').valueOf()).startOf('day').valueOf()))
                  })
                }, props.openProfileDialog)
              }
        </AccordionDetails>
      </Accordion>
    )
  }

  return (
    <div className={classes.root}>
      {/* showing day one (current date) bookings comming from parent component */}
      <Accordion expanded={isOpen} onChange={()=>setIsOpen(!isOpen)} className="root">
          <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          style={{}}
          id="panel1a-header">
              <Typography className={classes.heading}>
                  {moment().format("MMM Do YY")}
              </Typography>
              <hr className="accordion-hr" />
          </AccordionSummary>
          <AccordionDetails style={{}}>
              {bookingsHandler(props.stateData, props.openProfileDialog)}
          </AccordionDetails>
      </Accordion>

      {/* showing next 10 days bookings */}
      {
        dummyList.map((day)=>{
          return getNextBookings(day)
        })
      } 
    </div>
  );
}
