import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { reactLocalStorage } from 'reactjs-localstorage'

export class Bookings extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             isDoctor: true
        }
    }

    componentDidMount(){
        this.setState({
            isDoctor: reactLocalStorage.get('userType')
        })
    }
    
    
    render() {
        if (reactLocalStorage.get('userType') == '1'){
            return <Redirect to="/doctor-bookings" />
        }
        else {
            // window.alert('patient booking')
            return <Redirect to="/patient-bookings" />
        }
    }
}

export default Bookings
