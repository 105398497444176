import React, { Component } from 'react'
import './WeekStrip.css';
import moment from 'moment'
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';

export class WeekStrip extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            stripRange: 0
        }
    }

    /**
     * @description child component method to show strip body
     * @author Jagannath
     * @date 2020-10-14
     */
    WeekStripRange =() =>{
        const d = this.state.stripRange
        return (
            <div className="week-strip-button" onClick={()=>this.getWeekRange()}>
                {moment().subtract(d+7, 'day').format('MMM'+'D')}
                {' - '}
                {moment().subtract(d, 'day').format('MMM'+'D')}
            </div>
        )
    }

    /**
     * @description method handling click on strip arrow
     * @author Jagannath
     * @date 2020-10-14
     * @param event {left-0 right-1}
     */
    stripClickHandler(event){
        if(!event){
            this.setState({stripRange: this.state.stripRange + 7},()=>{
                this.getWeekRange()
            })
        }
        else if (event && this.state.stripRange){
            this.setState({stripRange: this.state.stripRange - 7}, ()=>{
                this.getWeekRange()
            })
        }
    }

    /**
     * @description method called on click a week range
     * @author Jagannath
     * @date 2020-10-13
     * @param d selected week range difference
     */
    getWeekRange = () => {
        const d = this.state.stripRange;
        const weekrange = {
            startDate : moment().subtract(d+7, 'day').valueOf(),
            endDate : moment().subtract(d, 'day').valueOf()
        }
        this.props.weekrange(weekrange)
    }

    render() {
        const {WeekStrip, WeekStripRange} = this
        return (
            <div>
                <div className="card-header d-flex week-strip">
                    <ArrowLeftIcon 
                        onClick={()=>this.stripClickHandler(0)} 
                        className="float-left mtb-auto arrow-font" />
                    <button 
                        type="button" 
                        className="float-center btn btn-default btn-sm">
                        <WeekStripRange />
                    </button>
                    <ArrowRightIcon 
                        onClick={()=>this.stripClickHandler(1)} 
                        className="float-right mtb-auto arrow-font" />
                </div>
            </div>
        )
    }
}

export default WeekStrip
