import React, { Component } from 'react'
import { CircularProgress } from '@material-ui/core'
import './Loader.css'

/**
 * @description Loader component
 * @author Jagannath
 * @date 2020-10-12
 * @param isLoader - (props) : boolean
 */
export class Loader extends Component {
    constructor(props) {
        super(props)
    }
    
    render() {
        const { isLoader } = this.props;
        if(isLoader){
            return (
                <div className="loader-container">
                    <CircularProgress
                        className="loader" 
                        style={{width: '80px', height:'80px'}} 
                        />
                </div>
            )
        }
        return <></>
    }
}

export default Loader
